import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';
import headerLogo from '../images/logo_inprint.svg';
import phoneIcon from '../images/icons/phone.svg';
import * as styles from './Header.module.scss';
import MobileMenu from './MobileMenu';
import DesktopMenu from './DesktopMenu';

const Header = () => (
  <header>
    <div className={styles.containerFluid}>
      <div className={styles.headerLogo}>
        <Link to="/">
          <img alt="Logo" src={headerLogo} />
        </Link>
      </div>
      <div className={`${styles.titleNav} textcenter`}>
        <p className={styles.h1}>
          <strong>In-Print, votre imprimeur partenaire</strong>
        </p>
        <MobileMenu />
        <DesktopMenu className="desktop-menu" />
      </div>
      <div className={styles.headerContact}>
        <p className={styles.headerPhone}>
          <a href="tel:+33561246535">
            <img src={phoneIcon} />
            05 61 24 65 35
          </a>
        </p>
        <Link to="/devis/" className={styles.quoteBtn}>
          Demande de devis
        </Link>
      </div>
    </div>
  </header>
);

Header.propTypes = {
  siteTitle: PropTypes.string,
};

Header.defaultProps = {
  siteTitle: ``,
};
export default Header;
