import React from 'react';
import MenuLinks from './MenuLinks';
import './MobileMenu.scss';
import Menu from '../images/icons/menu.svg';
import CloseMenu from '../images/icons/close_menu.svg';

class MobileMenu extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
    };
    this.showMenu = this.showMenu.bind(this);
    this.hideMenu = this.hideMenu.bind(this);
    this.toggleMenu = this.toggleMenu.bind(this);
    this.onGlobalClickEvent = this.onGlobalClickEvent.bind(this);
  }

  componentWillReceiveProps() {
    this.setState({ isOpen: false });
  }

  onGlobalClickEvent(event) {
    this.setState({ ...this.state, isOpen: false });
  }

  showMenu() {
    this.setState(() => ({ isOpen: true }));
  }

  hideMenu() {
    this.setState(() => ({ isOpen: false }));
  }

  toggleMenu() {
    this.state.isOpen === true ? this.hideMenu() : this.showMenu();
  }

  render() {
    const displayLinks =
      this.state.isOpen === true ? 'displayed' : 'notdisplayed';
    const openMenu = this.state.isOpen === true ? 'open' : '';
    const menuIcon = this.state.isOpen === true ? CloseMenu : Menu;

    return (
      <div className={`mobile-menu ${openMenu}`}>
        <button className="toggle-button primary" onClick={this.toggleMenu}>
          <img src={menuIcon} />
        </button>
        <MenuLinks className={displayLinks} />
      </div>
    );
  }
}

export default MobileMenu;
