import * as styles from './Footer.module.scss';
import { Link } from 'gatsby';
import React from 'react';
import logoOccitanie from '../images/partenaires/logo_occitanie.png';

const links = [
  { href: '/mentions-legales', label: `Mentions légales` },
  { href: '/faq/', label: `FAQ` },
  { href: '/sitemap/', label: `Plan du site` },
];

const Footer = () => (
  <footer>
    <div className="container-fluid">
      <div className={`${styles.row} row ${styles.nav}`}>
        <div className="col-sm-12">
          <nav className={styles.footerNav}>
            <ul>
              {links.map((link, index) => (
                <li key={index}>
                  <Link
                    to={link.href}
                    onClick={() => this.toggleMenu()}
                    activeClassName="active"
                  >
                    <span>{link.label}</span>
                  </Link>
                </li>
              ))}
            </ul>
          </nav>
          <div style={{textAlign: 'center', marginTop: '10px'}}>
            <a href="https://www.laregion.fr/" target="_blank"><img src={logoOccitanie} /></a>
          </div>
        </div>
      </div>
      <div className={`text-center ${styles.copyright}`}>
        <p>© 2019 - {new Date().getFullYear()} In-Print</p>
      </div>
    </div>
  </footer>
);

export default Footer;
