import React from 'react';
import { Link } from 'gatsby';
import * as styles from './MenuLinks.module.scss';

const links = [
  { href: '/', label: `Accueil`, hideSubLinks: true },
  {
    label: `La société`,
    subLinks: [
      {
        href: '/notre-aventure/',
        label: `Notre aventure`,
        hideSubLinks: true,
      },
      {
        href: '/chiffres-references/',
        label: `Chiffres clés & Références`,
        hideSubLinks: true,
      },
    ],
  },
  { href: '/nos-engagements/', label: `Nos engagements`, hideSubLinks: true },
  {
    label: `Nos Produits`,
    subLinks: [
      {
        href: '/grand-format',
        label: `Grand Format`,
        hideSubLinks: true,
      },
      {
        href: '/impression-tous-supports',
        label: `Impression tous supports`,
        hideSubLinks: true,
      },
    ],
  },
  { href: '/solution-revendeurs/', label: `Revendeurs`, hideSubLinks: true },
  { href: '/devis/', label: `Devis`, hideSubLinks: true },
  { href: '/contact/', label: `Contact`, hideSubLinks: true },
];

const MenuLinks = props => (
  <nav className={`${styles.mainNav} ${props.className}`}>
    <ul>
      {links.map((link, index) => (
        <HeaderLink link={link} key={index} />
      ))}
    </ul>
  </nav>
);

const HeaderLink = props => {
  const { link } = props;
  const subLinks = link.subLinks || [];
  const hideSubLinks = link.hideSubLinks === true;
  const isDisplayedOnMobile = hideSubLinks ? '' : 'submenu-is-displayed';
  const hasHref = link.href;

  return (
    <li className={isDisplayedOnMobile}>
      {hasHref ? (
        <Link
          to={link.href}
          onClick={() => this.toggleMenu()}
          activeClassName={`${styles.active} active`}
        >
          <span>{link.label} </span>
        </Link>
      ) : (
        <span>{link.label} </span>
      )}

      {subLinks.length > 0 && (
        <ul className={`${styles.subMenu} sub-menu`}>
          {subLinks.map((subLink, index) => (
            <HeaderLink link={subLink} key={index} />
          ))}
        </ul>
      )}
    </li>
  );
};

export default MenuLinks;
