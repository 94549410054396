import React from 'react';
import { Link } from 'gatsby';
import Cookies from 'universal-cookie';
import * as styles from './CookieNotice.module.scss';

const cookies = new Cookies();
const cookieName = 'inprint-cookie-notice-acknowledged';

const CookieNotice = () => {
  const [state, setState] = React.useState({
    mustDisplayNotice: !cookies.get(cookieName),
  });

  if (state.mustDisplayNotice) {
    return (
      <div className={styles.cookieContainer}>
        En poursuivant votre navigation sur ce site, vous acceptez l'utilisation
        de cookies. <Link to="/mentions-legales#cookies">En savoir plus</Link>.
        <button
          onClick={() => {
            cookies.set(cookieName, 'true', { path: '/' });
            setState({ mustDisplayNotice: !cookies.get(cookieName) });
          }}
        >
          x
        </button>
      </div>
    );
  }

  return <></>;
};

export default CookieNotice;
